<template>
  <div class="rq">
    <div class="rq-label" v-if="requestValue">
      {{requestValue.name + ' ' + requestValue.path + ' ' + requestValue.method}}
    </div>
    <fm-btn
      @click="conf">
      配置
    </fm-btn>
    <fm-form-dialog
      form-title="接口调用配置"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      form-width="800px"
      label-alone
      label-align="left"
      @formSubmit="formSubmit"
      @handleClose="handleClose">
    </fm-form-dialog>
  </div>
</template>

<script>
let dcopy = require('deep-copy')

export default {
  props: {
    value: Object
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  watch: {
    value: {
      handler () {
        this.requestValue = dcopy(this.value)
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    formParms () {
      return [{
        type: 'select',
        label: 'API配置',
        key: 'confApiId',
        check: {
          required: true
        },
        selectDatas: this.$store.getters.confApiDataList
      }]
    }
  },
  data () {
    return {
      openDialog: false,
      requestValue: {
        name: '',
        path: '',
        method: '',
        confApi: null
      }
    }
  },
  methods: {
    handleClose () {
      this.openDialog = false
    },
    formSubmit (data) {
      let confApi = this.$store.getters.confApiDataList.find(v => v.data.id === data.confApiId)
      if (confApi) {
        confApi = confApi.data
        this.requestValue.path = confApi.path
        this.requestValue.method = confApi.method
        this.requestValue.name = confApi.parm
        this.requestValue.confApi = confApi
      }
      this.$emit('change', Object.assign({}, this.requestValue))
      this.openDialog = false
    },
    conf () {
      this.openDialog = true
    }
  }
}
</script>

<style lang="less" scoped>
@import './base.less';
.rq {
  display: flex;
  padding-left: 1rem;
  align-items: center;
  .rq-label {
    margin-right: 1rem;
  }
}
</style>
