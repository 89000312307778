<template>
  <div>
    <base-sign-page
      :title-text="titleText"
      :note-text="noteText"
      :column-list="columnList"
      :data-list="confPageDataList"
      :form-parms="formParms"
      :his-data="chooseData"
      :openDialog="openDialog"
      @formHandleClose="openDialog = false"
      @formSubmit="formSubmit"
      @countDataChange="countDataChange"
      :count-fun="countFun"
      :show-search="true"
      v-loadingx="confPageLoading"
      @tableAction="tableAction"
      :table-actions="tableActions"
      :title-menus="titleMenus"
      @clickTitleMenu="clickTitleMenu"
      :need-pagination="true">
    </base-sign-page>
    <slot></slot>
  </div>
</template>

<script>
import BaseSignPage from '@/components/base/BaseSignPage'

import {
  confPageRequest as request
} from '@/api'

import {
  dateOperating
} from '@/fmlib'

export default {
  components: {
    BaseSignPage
  },
  watch: {
    loadTag () {
      this.loadData()
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    confPageDataList () {
      return this.$store.getters.confPageDataList
    },
    confPageLoading () {
      return this.$store.getters.confPageLoading
    },
    chooseConfPage: {
      get () {
        return this.$store.getters.chooseConfPage
      },
      set (value) {
        this.$store.dispatch('setChooseConfPage', value)
      }
    },
    tableActions () {
      return [{
        key: 'conf',
        label: '配置'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    titleMenus () {
      return [{
        key: 'add',
        label: '新增'
      }]
    },
    formParms: {
      get () {
        let data = [{
          type: 'input',
          label: '标题',
          key: 'title'
        // },
        // {
        //   type: 'input',
        //   label: '配置',
        //   key: 'conf'
        }]
        return data
      }
    },
    columnList: {
      get () {
        return [{
          field: 'title',
          title: '标题'
        }]
      }
    }
  },
  methods: {
    countFun (data) {
      return data.length
    },
    countDataChange (data) {
      this.noteText = '总数:' + data
    },
    tableAction (data) {
      this.chooseData = data.data
      if (data.action === 'edit') {
        this.openDialog = true
      } else if (data.action === 'del') {
        this.delData()
      } else if (data.action === 'conf') {
        this.chooseConfPage = data.data
        this.$store.dispatch('setConfPageShowChoose', false)
      }
    },
    async delData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除' + this.titleText + '吗?'})
      if (result) {
        request.del(this.chooseData.id).then(() => {
          this.$notify({
            title: '系统提示',
            message: this.titleText + '已删除',
            type: 'info'
          })
          this.loadData()
        })
      }
    },
    formSubmit (data) {
      for (let key in data) {
        if (data[key] instanceof Date) {
          data[key] = dateOperating.computeDay({days: 0, date: data[key], format: 'yy-mm-dd hh:mm:ss'})
        }
      }
      if (this.chooseData) {
        request.update(this.chooseData.id, data).then(() => {
          this.$notify({
            title: '系统提示',
            message: this.titleText + '修改完成',
            type: 'success'
          })
          this.loadData()
          this.openDialog = false
        })
      } else {
        request.add(data).then(() => {
          this.$notify({
            title: '系统提示',
            message: this.titleText + '新增完成',
            type: 'success'
          })
          this.loadData()
          this.openDialog = false
        })
      }
    },
    clickTitleMenu (parm) {
      this.chooseData = null
      if (parm === 'add') {
        this.openDialog = true
      }
    },
    loadData () {
      this.$store.dispatch('loadConfPageDataList')
    }
  },
  data () {
    return {
      titleText: '页面配置',
      dataList: [],
      noteText: '',
      chooseData: null,
      openDialog: false,
      openDialogFile: false
    }
  }
}
</script>
