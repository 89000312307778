<template>
  <div class="home-page">
    <page-list
      class="left-c-ps"
      v-show="confPageShowChoose">
    </page-list>
    <div class="left-c" v-if="!confPageShowChoose">
      <fm-title style="background: #fff;" title-text="页面配置" :title-menus="titleMenus" @clickTitleMenu="clickTitleMenu"></fm-title>
      <page-search class="c-item"></page-search>
      <page-title class="c-item"></page-title>
      <page-table class="c-item"></page-table>
    </div>
    <div class="right-c">
      <norm-page-cmp :pageConfig="usePageConf"></norm-page-cmp>
    </div>
  </div>
</template>

<script>

import normPageCmp from './norm_page_cmp'
import pageList from './conf_page/page_list'
import pageSearch from './conf_page/page_search'
import pageTitle from './conf_page/page_title'
import pageTable from './conf_page/page_table'
export default {
  components: {
    normPageCmp,
    pageList,
    pageSearch,
    pageTitle,
    pageTable
  },
  computed: {
    usePageConf () {
      return this.$store.getters.usePageConf
    },
    confPageShowChoose: {
      get () {
        return this.$store.getters.confPageShowChoose
      },
      set (value) {
        this.$store.dispatch('setConfPageShowChoose', value)
      }
    }
  },
  data () {
    return {
      titleMenus: [{
        key: 'choose',
        label: '选择'
      },
      {
        key: 'save',
        label: '保存'
      }]
    }
  },
  created () {
    this.$store.dispatch('loadConfApiDataList')
  },
  methods: {
    clickTitleMenu (key) {
      if (key === 'choose') {
        this.confPageShowChoose = true
      } else {
        this.$store.dispatch('saveConfPageData')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.home-page {
  background: #f8f8f9;
  display: flex;
  height: 100%;
  width: 100%;
}
.fm-form-item-label {
  width: 8rem;
  vertical-align: middle;
  font-size: 1.1rem;
  color: #515a6e;
  text-align: right;
  line-height: 1;
  padding: 0 10px;
  padding-right: 18px;
}
.form-item-table {
  height: 20rem;
}
.form-item {
  display: flex;
  height: 3rem;
  align-items: center;
}
.c-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-radius: 0.3rem;
  border: 1px dotted #999;
  background: #fff;
}
.left-c-ps {
  flex: 1;
}
.left-c {
  flex: 1;
  padding: 1rem 1rem 2rem 1rem;
  display: flex;
  flex-direction: column;
  height: calc(100% - 6rem);
  max-height: calc(100% - 6rem);
  overflow-y: auto;
}
.right-c {
  border: 1rem solid #333;
  background: #f8f8f9;
  flex: 1;
}
</style>
