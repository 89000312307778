<template>
  <div>
    <fm-title title-text="表格栏">
    </fm-title>
    <div class="form-item">
      <div class="form-item-label">表格列</div>
      <div class="form-item-content">
        <form-parm v-model="column"></form-parm>
      </div>
    </div>
    <div class="form-item">
      <div class="form-item-label">表格按钮</div>
      <div class="form-item-content">
        <menus v-model="menu"></menus>
      </div>
    </div>
  </div>
</template>

<script>
import menus from './base/menus'
import FormParm from './base/form_parm'

export default {
  components: {
    menus,
    FormParm
  },
  computed: {
    column: {
      get () {
        return this.$store.getters.confPageData.tableConf.column
      },
      set (value) {
        this.$store.dispatch('updateConfPageData', {keys: ['tableConf', 'column'], data: value})
      }
    },
    menu: {
      get () {
        return this.$store.getters.confPageData.tableConf.menu
      },
      set (value) {
        this.$store.dispatch('updateConfPageData', {keys: ['tableConf', 'menu'], data: value})
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import './style.less';
</style>
