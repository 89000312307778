import { render, staticRenderFns } from "./page_table.vue?vue&type=template&id=be7dd152&scoped=true&"
import script from "./page_table.vue?vue&type=script&lang=js&"
export * from "./page_table.vue?vue&type=script&lang=js&"
import style0 from "./page_table.vue?vue&type=style&index=0&id=be7dd152&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be7dd152",
  null
  
)

export default component.exports