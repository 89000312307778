<template>
  <div class="f-p">
    <div class="f-p-data">
      <fm-table
        :column-list="columnList"
        :data-list="[{}].concat(menu)"
        @tableAction="tableAction"
        :table-actions="tableActions">
      </fm-table>
    </div>
    <fm-form-dialog
      form-title="表单配置"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="chooseData"
      label-alone
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="handleClose">
    </fm-form-dialog>
  </div>
</template>

<script>
let dcopy = require('deep-copy')

const typeList = [{key: 'form_to_add', label: '表单新增'}, {key: 'form_to_edit', label: '表单修改'}, {key: 'confirm_to_del', label: '确认框删除'}]

export default {
  props: {
    value: Array
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  watch: {
    value: {
      handler () {
        this.menu = dcopy(this.value)
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    columnList () {
      return [{
        title: '名称',
        field: 'label'
      },
      {
        title: '键',
        field: 'key'
      },
      {
        title: '类型',
        field: 'type',
        render: function (h, rowData) {
          return h('div', (typeList.find(v => v.key === rowData.type) || {}).label || '')
        }
      },
      {
        title: '配置',
        field: 'conf'
      }]
    },
    formParms () {
      return [{
        type: 'input',
        label: '名称',
        key: 'label',
        check: {
          required: true
        }
      },
      {
        type: 'input',
        label: '键',
        key: 'key',
        fmDisabled: !this.isNew,
        check: {
          required: true
        }
      },
      {
        type: 'select',
        label: '类型',
        key: 'type',
        selectDatas: typeList,
        check: {
          required: true
        }
      }]
    }
  },
  data () {
    return {
      isNew: false,
      chooseData: null,
      tableActions: [{
        key: 'add',
        label: '新增',
        show: (data) => {
          return !data.key
        }
      },
      {
        key: 'edit',
        label: '修改',
        show: (data) => {
          return data.key
        }
      },
      {
        key: 'del',
        label: '删除',
        show: (data) => {
          return data.key
        }
      }],
      menu: [],
      openDialog: false
    }
  },
  methods: {
    tableAction (parm) {
      if (parm.action === 'add') {
        this.isNew = true
        this.chooseData = null
        this.openDialog = true
      } else if (parm.action === 'edit') {
        this.chooseData = parm.data
        this.openDialog = true
        this.isNew = false
      } else if (parm.action === 'del') {
        this.chooseData = parm.data
        this.menu = this.menu.filter(v => v.key !== this.chooseData.key)
        this.$emit('change', this.menu.map((v) => {
          let a = {}
          Object.keys(v).forEach((v2) => {
            a[v2] = v[v2]
          })
          return a
        }))
      }
    },
    handleClose () {
      this.openDialog = false
    },
    formSubmit (data) {
      if (this.isNew) {
        if (!this.menu.find(v => v.key === data.key)) {
          this.menu.push(data)
        }
      } else {
        let i = 0
        while (i < this.menu.length) {
          if (this.menu[i].key === data.key) {
            this.menu[i] = data
          }
          i += 1
        }
      }
      this.$emit('change', this.menu.map((v) => {
        let a = {}
        Object.keys(v).forEach((v2) => {
          a[v2] = v[v2]
        })
        return a
      }))
      this.openDialog = false
    },
    conf () {
      this.openDialog = true
    }
  }
}
</script>

<style lang="less" scoped>
@import './base.less';
.f-p {
  align-items: center;
  margin-left: 1rem;
  width: calc(100% - 1rem);
  .f-p-data {
    border: 1px solid #dcdee2;
    border-top: 0px;
    height: 10rem;
    width: calc(100% - 1rem);
  }
}
</style>
