<template>
  <div>
    <fm-title title-text="搜索栏">
    </fm-title>
    <div class="form-item">
      <div class="form-item-label">获取数据</div>
      <div class="form-item-content">
        <request v-model="loadDataRequest"></request>
      </div>
    </div>
    <div class="form-item">
      <div class="form-item-label">查询条件
        <fm-btn @click="sc">自动生成</fm-btn>
      </div>
      <div class="form-item-content">
        <form-parm v-model="searchParm"></form-parm>
      </div>
    </div>
  </div>
</template>

<script>
import Request from './base/request'
import FormParm from './base/form_parm'

export default {
  components: {
    Request,
    FormParm
  },
  methods: {
    getParm (confQuery) {
      let parmList = confQuery.conditions.filter(v => v.value && v.value[0] === '{' && v.value[v.value.length - 1] === '}').map((v) => {
        return {
          label: v.column,
          key: v.column,
          type: 'input'
        }
      })
      return parmList
    },
    sc () {
      let confApiConf = this.loadDataRequest.confApi && this.loadDataRequest.confApi.dataConf ? JSON.parse(this.loadDataRequest.confApi.dataConf) : null
      let confQueryConf = confApiConf && confApiConf.confQuery && confApiConf.confQuery.conf ? JSON.parse(confApiConf.confQuery.conf) : null
      if (confQueryConf) {
        this.searchParm = this.getParm(confQueryConf)
      }
    }
  },
  computed: {
    loadDataRequest: {
      get () {
        return this.$store.getters.confPageData.searchConf.loadDataRequest
      },
      set (value) {
        this.$store.dispatch('updateConfPageData', {keys: ['searchConf', 'loadDataRequest'], data: value})
      }
    },
    searchParm: {
      get () {
        return this.$store.getters.confPageData.searchConf.searchParm
      },
      set (value) {
        this.$store.dispatch('updateConfPageData', {keys: ['searchConf', 'searchParm'], data: value})
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import './style.less';
</style>
