<template>
  <div>
    <fm-title title-text="标题栏">
    </fm-title>
    <div class="form-item">
      <div class="form-item-label">标题</div>
      <div class="form-item-content">
        <div style="width: 10rem;margin-left: 1rem;">
          <fm-input v-model="title"></fm-input>
        </div>
      </div>
    </div>
    <div class="form-item">
      <div class="form-item-label">标题菜单</div>
      <div class="form-item-content">
        <menus v-model="menu"></menus>
      </div>
    </div>
  </div>
</template>

<script>
import menus from './base/menus'

export default {
  components: {
    menus
  },
  computed: {
    title: {
      get () {
        return this.$store.getters.confPageData.titleConf.title
      },
      set (value) {
        this.$store.dispatch('updateConfPageData', {keys: ['titleConf', 'title'], data: value})
      }
    },
    menu: {
      get () {
        return this.$store.getters.confPageData.titleConf.menu
      },
      set (value) {
        this.$store.dispatch('updateConfPageData', {keys: ['titleConf', 'menu'], data: value})
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import './style.less';
</style>
